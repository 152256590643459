import { formatValue } from 'react-currency-input-field';
import { CURRENCY_MAP } from '../constants/currency';
import { NumberType } from '../../types';
import MICRO_CENT_CURRENCIES_MAPPING from '../constants/microcent_currency';
import { Money } from '../../graphql';

export const formatToNumber = (num?: NumberType) => {
  const normalized =
    typeof num === 'string' ? num.replace(/[^-\d\\.]/g, '') : num;

  if (normalized || normalized === 0) {
    return Number(normalized);
  }

  return null;
};

export const formatToCents = (num?: NumberType) => {
  const number = formatToNumber(num);
  if (number) {
    // The `formatToNumber` function used `Number`, which can create weird floating-point
    // inconsistencies. Example: `Number('1.10') * 100` is `110.00000000000001`.
    // Example: `Number('2.01') * 100` is `200.99999999999997`.
    return Math.round(number * 100);
  }
  return number;
};

export const formatToMicroCents = (num?: NumberType) => {
  const number = formatToNumber(num);
  if (number) {
    return number * 10000000;
  }
  return number;
};

export const formatNumber = (num?: number) => {
  if (num === undefined || num === null) {
    return '—';
  }
  return Intl.NumberFormat(undefined).format(num);
};

export const formatNumberTruncatedDecimal = (num?: number) => {
  if (num === undefined) {
    return '—';
  }
  return Intl.NumberFormat(undefined).format(Math.floor(num));
};

export const formatNumberWithPrecision = (num?: number, precision = 2) => {
  if (num === undefined) {
    return '—';
  }
  return Intl.NumberFormat(undefined, {
    maximumFractionDigits: precision,
  }).format(num);
};

export const formatNumberText = (num?: NumberType, emptyValue = 'N/A') =>
  num && typeof num === 'number' ? formatNumber(num) : emptyValue;

export const formatCentsToDollars = (num?: NumberType) => {
  const number = formatToNumber(num);
  if (number) {
    return number / 100;
  }
  return number;
};

export const formatMicroCentsToDollars = (num?: NumberType) => {
  const number = formatToNumber(num);
  if (number) {
    return number / 10000000;
  }
  return number;
};

export const hasMicroCentCurrency = (currency: string) => {
  if (currency) {
    return (
      Object.keys(MICRO_CENT_CURRENCIES_MAPPING).includes(currency) ||
      Object.values(MICRO_CENT_CURRENCIES_MAPPING).includes(currency)
    );
  }

  return false;
};

export const getCentCurrency = (currency: string) => {
  if (currency) {
    return currency.replace('8', ''); // remove 8 from USD8;
  }
  return currency;
};

export const getMicroCentCurrency = (currency: string) => {
  if (currency && hasMicroCentCurrency(currency)) {
    return MICRO_CENT_CURRENCIES_MAPPING[currency];
  }

  return currency;
};

export const formatToCurrencyCents = (currency: string, num: NumberType) =>
  hasMicroCentCurrency(currency) ? formatToMicroCents(num) : formatToCents(num);

export const formatFractionalToDollars = (currency: string, num: NumberType) =>
  hasMicroCentCurrency(currency)
    ? formatMicroCentsToDollars(num)
    : formatCentsToDollars(num);

export const formatFractionalValue = (value: number) =>
  value.toLocaleString('en-US', {
    minimumFractionDigits: Math.ceil(value % 1) * 2,
  });

export const DECIMAL_LIMIT = {
  DEFAULT: 2,
  NUM_OPTIONS: 4,
  NUM_EXERCISE: 4,
  NUM_SHARES: 3,
  VESTING_PERCENTAGE: 3,
};

export const CURRENCY_PRECISION = {
  DEFAULT: 2,
  MAX: 6,
};

export const CURRENCY_STEP = {
  DEFAULT: 0.01,
  MAX: 0.00001,
};

export const getCurrencySign = (currency: string) =>
  CURRENCY_MAP[currency] || currency;

export const formatMoney = (
  value: number,
  currency = 'USD',
  options: any = {},
) => {
  const decimalScale = options.precision ?? CURRENCY_PRECISION.DEFAULT;
  const forcePrecision = options.forcePrecision || false;
  const noDefaultPrecision = options.noDefaultPrecision || false;
  if (value || value === 0) {
    let precision = value % 1 === 0 ? 0 : decimalScale;
    if (forcePrecision) {
      precision = decimalScale;
    }
    if (noDefaultPrecision) {
      precision = undefined;
    }

    let prefix = getCurrencySign(currency);
    if (options.showCurrencyCode) {
      prefix = `${currency} ${prefix}`;
    }

    return formatValue({
      value: Number(value).toString(),
      groupSeparator: ',',
      decimalSeparator: '.',
      decimalScale: precision,
      prefix,
    });
  }

  return null;
};

export const formatMoneyObjectToMoney = (
  moneyObj?: Pick<Money, 'currency' | 'fractional'>,
) => {
  if (moneyObj && moneyObj.currency && moneyObj.fractional) {
    const isMicroCent = hasMicroCentCurrency(moneyObj.currency);
    const dollars = formatFractionalToDollars(
      moneyObj.currency,
      moneyObj.fractional,
    );
    return formatMoney(dollars, getCentCurrency(moneyObj.currency), {
      noDefaultPrecision: isMicroCent,
    });
  }

  return '—';
};
