import { ObjectType } from '../../types';

export const CURRENCY_MAP: ObjectType = {
  USD: '$',
  INR: '₹',
  SGD: 'S$',
  ISK8: 'ISK',
  MXN8: 'MXN',
  RUB8: 'RUB',
  ZAR8: 'ZAR',
  TRY8: 'TRY',
  BRL8: 'R$',
  IDR8: 'IDR',
  USD8: '$',
  CAD8: 'C$',
  GBP8: '£',
  EUR8: '€',
  SEK8: 'SEK',
  CHF8: 'CHF',
  INR8: '₹',
  NOK8: 'NOK',
  AUD8: 'AU$',
  DKK8: 'DKK',
  JPY8: 'JPY',
  CNY8: 'CNY',
  HKD8: 'HK$',
  NZD8: 'NZ$',
  KRW8: '₩',
  SGD8: 'S$',
  EUR: '€',
  GBP: '£',
  AUD: '$',
  CAD: '$',
  JPY: '¥',
  BYR: 'Br',
  CRC: '₡',
  CUC: '$',
  CUP: '$',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr.',
  DOP: '$',
  DZD: 'د.ج',
  EGP: 'ج.م',
  ERN: 'Nfk',
  ETB: 'Br',
  FJD: '$',
  FKP: '£',
  GEL: 'ლ',
  GHS: '₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'Fr',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr.',
  JMD: '$',
  JOD: 'د.ا',
  KES: 'KSh',
  KGS: 'som',
  KHR: '៛',
  KMF: 'Fr',
  KPW: '₩',
  KRW: '₩',
  KWD: 'د.ك',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: 'ل.ل',
  LKR: '₨',
  LRD: '$',
  LSL: 'L',
  LYD: 'ل.د',
  MAD: 'د.م.',
  MDL: 'L',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'P',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'MVR',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MTn',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: 'ر.ع.',
  PAB: 'B/.',
  PEN: 'S/',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'ر.ق',
  RON: 'Lei',
  RSD: 'РСД',
  RUB: '₽',
  RWF: 'FRw',
  SAR: 'ر.س',
  SBD: '$',
  SCR: '₨',
  SDG: '£',
  SEK: 'kr',
  SHP: '£',
  SKK: 'Sk',
  SLL: 'Le',
  SOS: 'Sh',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  SVC: '₡',
  SYP: '£S',
  SZL: 'E',
  THB: '฿',
  TJS: 'ЅМ',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRY: '₺',
  TTD: '$',
  TWD: '$',
  TZS: 'Sh',
  UAH: '₴',
  UGX: 'USh',
  UYU: '$U',
  UZS: "so'm",
  VES: 'Bs',
  VND: '₫',
  VUV: 'Vt',
  WST: 'T',
  XAF: 'CFA',
  XAG: 'oz t',
  XAU: 'oz t',
  XCD: '$',
  XDR: 'SDR',
  XOF: 'Fr',
  XPD: 'oz t',
  XPF: 'Fr',
  XPT: 'oz t',
  YER: '﷼',
  ZAR: 'R',
  ZMK: 'ZK',
  ZMW: 'K',
  BCH: '₿',
  BTC: '₿',
  JEP: '£',
  GGP: '£',
  IMP: '£',
  CNH: '¥',
  EEK: 'KR',
  LTL: 'Lt',
  LVL: 'Ls',
  MRO: 'UM',
  MTL: '₤',
  TMM: 'm',
  ZWD: '$',
  AED: 'د.إ',
  ZWN: '$',
  ZWR: '$',
  VEF: 'Bs.F',
  ZWL: '$',
  AFN: '؋',
  ALL: 'L',
  AMD: 'դր.',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'КМ',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв.',
  BHD: 'ب.د',
  BIF: 'Fr',
  BMD: '$',
  BND: '$',
  BOB: 'Bs.',
  BRL: 'R$',
  BSD: '$',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BZD: '$',
  CDF: 'Fr',
  CHF: 'CHF',
  CLF: 'UF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
};
