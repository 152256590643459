import { ObjectType } from '../../types';

const MICRO_CENT_CURRENCIES_MAPPING: ObjectType = {
  USD: 'USD8',
  CAD: 'CAD8',
  EUR: 'EUR8',
  GBP: 'GBP8',
  SEK: 'SEK8',
  CHF: 'CHF8',
  INR: 'INR8',
  NOK: 'NOK8',
  AUD: 'AUD8',
  DKK: 'DKK8',
  JPY: 'JPY8',
  CNY: 'CNY8',
  HKD: 'HKD8',
  NZD: 'NZD8',
  KRW: 'KRW8',
  SGD: 'SGD8',
  MXN: 'MXN8',
  RUB: 'RUB8',
  ZAR: 'ZAR8',
  TRY: 'TRY8',
  BRL: 'BRL8',
  IDR: 'IDR8',
  ISK: 'ISK8',
};

export default MICRO_CENT_CURRENCIES_MAPPING;
