import moment from 'moment';
import { DateType, NumberType } from '../../types';

export const SERVER_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const getMomentToday = () => moment().endOf('day');

export const getMomentDate = (date?: DateType) => moment(date);

export const futureDateInWeeks = (num: NumberType) =>
  moment().add(num, 'weeks');

export const futureDateInYears = (num: NumberType) =>
  moment().add(num, 'years');

export const pastDateInDays = (num: NumberType) =>
  moment()
    .startOf('day')
    .subtract(num, 'day');

export const getDate = (date: DateType) => {
  if (date) {
    return getMomentDate(date).toDate();
  }
  return null;
};

export const getDateYear = (date: DateType) =>
  date ? getMomentDate(date).year() : null;

export const getPastDates = (daysBack = 7, fromPrevDay?: NumberType) =>
  [...Array(daysBack)]
    .map((_, i) => {
      const dayIndex = fromPrevDay ? i + 1 : i;
      return pastDateInDays(dayIndex).toDate();
    })
    .reverse();

export const formatDate = (
  date: DateType,
  emptyValue = '',
  endOfMillenValue = 'Future Date',
) => {
  if (date) {
    const formattedDate = getMomentDate(date).format('MMM D, YYYY');
    if (formattedDate === 'Dec 31, 9999') {
      return endOfMillenValue;
    }
    return formattedDate;
  }
  return emptyValue;
};

export const formatWithoutTimezone = (
  date: DateType,
  emptyValue = '',
  format = 'MMM D, YYYY',
) => {
  if (date) {
    return getMomentDate(date)
      .utc(false)
      .format(format);
  }
  return emptyValue;
};

export const formatDateInMDY = (date: DateType, emptyValue = '') => {
  if (date) {
    return getMomentDate(date).format(DATE_FORMAT);
  }
  return emptyValue;
};

export const formatDateTime = (date: DateType, emptyValue = '') => {
  if (date) {
    return getMomentDate(date).format('MMM D, YYYY hh:mm:ss A');
  }
  return emptyValue;
};

export const formatDateTimeHuman = (date: DateType, emptyValue = '') => {
  if (date) {
    return getMomentDate(date).format('MMM D, YYYY h:mm A');
  }
  return emptyValue;
};

export const formatDateHuman = (date: DateType, emptyValue = '') => {
  if (date) {
    return getMomentDate(date).format('MMM D, YYYY');
  }
  return emptyValue;
};

export const formatServerDate = (dt: DateType) =>
  dt ? moment(dt, DATE_FORMAT).format(SERVER_FORMAT) : '';

export const convertToServerDate = (dt: DateType) =>
  dt ? moment(dt).format(SERVER_FORMAT) : '';

const padLeft = (str: number, pad: string, len: number) =>
  (new Array(len + 1).join(pad) + str).slice(-len);

export const formatSecondsToString = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${padLeft(minutes, '0', 2)}:${padLeft(seconds, '0', 2)}`;
};

// input 2024-04 => output 04/24;
export const formatExpiryDate = (date: string = '') =>
  date
    .split('-')
    .reverse()
    .map((d: string) => padLeft(Number(d), '0', 2))
    .join('/');

export const DEFAULT_VIEW_DATE = convertToServerDate(getMomentToday());

// When `omitSuffix` is falsey, outputs something like '12 days ago'. When truthy, outputs '12 days'.
export const fromNow = (date: DateType, omitSuffix?: boolean) => {
  const base = moment(date).fromNow(omitSuffix);
  return base.startsWith('a ') ? `1 ${base.substring(2)}` : base; // Replaces 'a year' with '1 year'
};

export const DEFAULT_SERVER_DATE = formatServerDate(new Date());

export const sortArrayByDate = (array: any[], dateKey: string) => {
  const sortedArray = array.sort((a, b) => {
    const aDate = a[dateKey];
    const bDate = b[dateKey];
    if (aDate && bDate) {
      return (new Date(aDate) as any) - (new Date(bDate) as any);
    }
    if (!aDate && !bDate) {
      return 0;
    }
    if (!aDate) {
      return 1;
    }
    if (!bDate) {
      return -1;
    }
    return -1;
  });
  return sortedArray;
};

export const getFirstEffectiveDate = (array: any[], dateKey: string) => {
  if (array?.length) {
    const sortedArray = sortArrayByDate(array, dateKey);
    return sortedArray[0]?.[dateKey];
  }
  return null;
};

export const isInvalidDate = (date: Date) =>
  date?.toString() === 'Invalid Date';
